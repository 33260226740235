#headshot {
    width: 40%;
}

.company-logo {
    height: 175px;
    margin-right: 20px;
    margin-left: 20px;
    margin-top: 5px;
    margin-bottom: 5px;
}

#experience {
    width: 50%;
    float: right;
    min-height: 100%;
    flex: 1;
}
  
#about-me {
    width: 50%;
    float: left;
    min-height: 100%;
    flex: 1;
}

#home-info-row {
    margin-top: 20px;
    display: flex;
}