.header {
  background-image: linear-gradient(to right, mediumpurple, lightskyblue);;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.navbar a {
  color: white;

}

.navbar a:link { 
  text-decoration: none; 
}

.navbar a:hover {
  color: white;
  text-decoration: underline;
}

#name {
  font-size: 50px; 
  height: 60px;
}

.navbar {
  font-size: 20px;
  text-align: center;
}

#page {
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 30px;
  margin-bottom: 30px;
}

.App {
  font-family: 'Style Script';
  font-size: 18px;
}

#footer {
  margin-bottom: 15px;
  width: 100%;
}

#icons {
  font-size: x-large;
}

#icons a:link { 
  text-decoration: none; 
}