.description-col {
    width: 65%;
}

.picture-col {
    width: 35%;
}

.project-image {
    width: 35%;
}

.project-image-div {
    text-align: center;
}

table img {
    display: block;
    width: 100%;
}